/* ChatWindow.css */

/* @keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.fade-in {
  animation: fadeIn 0.5s ease-in forwards;
}

.shimmer-text {
  position: relative;
  display: inline-block;
}

.shimmer-text::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: translateX(-100%);
  animation: shimmer 2s infinite;
  mix-blend-mode: lighten;
  pointer-events: none;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}


.chat-window {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    padding: 1rem;
  }
  
  .message-container {
    display: flex;
    margin-bottom: 1rem;
  }
  
  .message-container.user {
    justify-content: flex-end;
  }
  
  .message-bubble {
    max-width: 70%;
    padding: 0.75rem 1rem;
    border-radius: 1rem;
    overflow-wrap: break-word;
  }
  
  .message-bubble.user {
    background-color: #1c7ed6;
    color: white;
    border-bottom-right-radius: 0;
  }
  
  .message-bubble.assistant {
    background-color: #f1f3f5;
    color: #212529;
    border-bottom-left-radius: 0;
  }
  
  .markdown-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .markdown-content > *:first-child {
    margin-top: 0;
  }
  
  .markdown-content > *:last-child {
    margin-bottom: 0;
  }
  
  .markdown-content p {
    margin-bottom: 0.5rem;
  }
  
  .markdown-content h1,
  .markdown-content h2,
  .markdown-content h3,
  .markdown-content h4,
  .markdown-content h5,
  .markdown-content h6 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .markdown-content h1 { font-size: 1.5em; }
  .markdown-content h2 { font-size: 1.25em; }
  .markdown-content h3 { font-size: 1.1em; }
  .markdown-content h4 { font-size: 1em; }
  .markdown-content h5 { font-size: 0.9em; }
  .markdown-content h6 { font-size: 0.8em; }
  
  .markdown-content ul,
  .markdown-content ol {
    margin-left: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .markdown-content li {
    margin-bottom: 0.25rem;
  }
  
  .markdown-content a {
    color: #228be6;
    text-decoration: none;
  }
  
  .markdown-content a:hover {
    text-decoration: underline;
  }
  
  .markdown-content code {
    background-color: rgba(0, 0, 0, 0.1);
    padding: 0.2em 0.4em;
    border-radius: 3px;
    font-family: monospace;
    font-size: 0.9em;
  }

  .markdown-content pre {
    max-width: 100%;
    overflow-x: auto;
  }
  
  .markdown-content pre code {
    background-color: transparent;
    padding: 0;
    font-size: 0.9em;
  }
  
  .markdown-content blockquote {
    border-left: 4px solid #ced4da;
    padding-left: 1rem;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    color: #495057;
  }
  
  .markdown-content table {
    border-collapse: collapse;
    margin-bottom: 1rem;
    width: 100%;
  }
  
  .markdown-content th,
  .markdown-content td {
    border: 1px solid #dee2e6;
    padding: 0.5rem;
    text-align: left;
  }
  
  .markdown-content th {
    background-color: #e9ecef;
    font-weight: bold;
  }
  
  .markdown-content .hljs {
    display: block;
    overflow-x: auto;
    padding: 0.5em;
    background: #2d2d2d;
    color: #ccc;
  }
  
  .markdown-content .hljs-keyword,
  .markdown-content .hljs-selector-tag,
  .markdown-content .hljs-tag {
    color: #e2777a;
  }
  
  .markdown-content .hljs-template-tag {
    color: #e2777a;
  }
  
  .markdown-content .hljs-number {
    color: #e2777a;
  }
  
  .markdown-content .hljs-variable,
  .markdown-content .hljs-template-variable,
  .markdown-content .hljs-attr {
    color: #7ec699;
  }
  
  .markdown-content .hljs-literal {
    color: #7ec699;
  }
  
  .markdown-content .hljs-subst {
    color: #8be9fd;
  }
  
  .markdown-content .hljs-title,
  .markdown-content .hljs-name,
  .markdown-content .hljs-selector-id,
  .markdown-content .hljs-selector-class,
  .markdown-content .hljs-section,
  .markdown-content .hljs-type {
    color: #ffa07a;
  }
  
  .markdown-content .hljs-symbol,
  .markdown-content .hljs-bullet,
  .markdown-content .hljs-built_in,
  .markdown-content .hljs-builtin-name,
  .markdown-content .hljs-addition,
  .markdown-content .hljs-variable,
  .markdown-content .hljs-link {
    color: #f1fa8c;
  }
  
  .markdown-content .hljs-comment,
  .markdown-content .hljs-quote,
  .markdown-content .hljs-meta,
  .markdown-content .hljs-deletion {
    color: #6272a4;
  }
  
  .markdown-content .hljs-keyword,
  .markdown-content .hljs-selector-tag,
  .markdown-content .hljs-literal,
  .markdown-content .hljs-title,
  .markdown-content .hljs-section,
  .markdown-content .hljs-doctag,
  .markdown-content .hljs-type,
  .markdown-content .hljs-name,
  .markdown-content .hljs-strong {
    font-weight: bold;
  }
  
  .markdown-content pre::-webkit-scrollbar {
    height: 6px;
  }
  
  .markdown-content pre::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .markdown-content pre::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }
  
  .markdown-content pre::-webkit-scrollbar-thumb:hover {
    background: #555;
  }


  .markdown-content .hljs-emphasis {
    font-style: italic;
  }

  .overflow-x-auto::-webkit-scrollbar {
    height: 6px;
  }
  
  .overflow-x-auto::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .overflow-x-auto::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }
  
  .overflow-x-auto::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .code-block {
    position: relative;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .code-container {
    max-height: var(--max-height, 400px);
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }
  
  .code-container.expanded {
    overflow: visible;
    max-height: none !important;
  }
  
  .code-container pre {
    margin: 0;
    white-space: pre;
    overflow-wrap: normal;
    overflow-x: auto;
  }
  
  .code-block > div:not(.absolute) {
    max-height: 800px;
    transition: max-height 0.3s ease-in-out;
  }
  
  .code-block > div.expanded {
    max-height: none !important;
  }
  
  .code-block pre {
    margin: 0;
  }
  
  .markdown-content pre {
    max-width: 100%;
    overflow-x: auto;
  }
  
  .markdown-content pre::-webkit-scrollbar {
    height: 6px;
  }
  
  .markdown-content pre::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .markdown-content pre::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }
  
  .markdown-content pre::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .fade-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    background: linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.9));
    pointer-events: none;
  }
  
  .code-container::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  .code-container::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .code-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  
  .code-container::-webkit-scrollbar-thumb:hover {
    background: #555;
  } */

  @keyframes slide {
    0% {
      transform: translateX(-100%);
    }
    50% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .animate-slide {
    animation: slide 1.5s linear infinite;
  }
  
  @keyframes pulse-scale {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
  
  .animate-pulse-scale {
    animation: pulse-scale 1.4s infinite ease-in-out both;
  }
