/* Base styles - scope everything under .landing-page */
.landing-page {
  width: 100%;
  overflow-x: hidden;
}

/* Set variables on the landing page container instead of :root */
.landing-page {
  --primary-color: #7209B7;
  --primary-light: #9D4EDD;
  --primary-dark: #560BAD;
  --secondary-color: #4CC9F0;
  --accent-color: #F72585;
  --text-color: #333333;
  --text-light: #666666;
  --text-dark: #111111;
  --bg-color: #ffffff;
  --bg-dark: #f5f5f7;
  --success-color: #10B981;
  --border-radius: 12px;
  --card-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  --transition: all 0.3s ease;
}

/* Dark mode variables */
@media (prefers-color-scheme: dark) {
  .landing-page {
    --text-color: #f5f5f7;
    --text-light: #a1a1a6;
    --text-dark: #ffffff;
    --bg-color: #121212;
    --bg-dark: #1e1e1e;
    --card-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  }
}

/* Landing page body styles */
.landing-page {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: var(--text-color);
  background-color: var(--bg-color);
  line-height: 1.6;
  overflow-x: hidden;
}

/* Typography - scope with .landing-page */
.landing-page h1, 
.landing-page h2, 
.landing-page h3, 
.landing-page h4, 
.landing-page h5, 
.landing-page h6 {
  margin: 0;
  line-height: 1.2;
  font-weight: 700;
}

.landing-page h1 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
}

.landing-page h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.landing-page h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.landing-page p {
  margin: 0 0 1rem;
}

.landing-page a {
  color: var(--primary-color);
  text-decoration: none;
  transition: var(--transition);
}

.landing-page a:hover {
  color: var(--primary-light);
}

/* Continue prefixing all other selectors with .landing-page */
.landing-page .section-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.landing-page .section-header {
  text-align: center;
  margin-bottom: 3rem;
}

.landing-page .section-header p {
  font-size: 1.2rem;
  color: var(--text-light);
  max-width: 600px;
  margin: 0 auto;
}

/* Buttons */
.landing-page .primary-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background: linear-gradient(135deg, var(--primary-color), var(--primary-dark));
  color: white;
  font-weight: 600;
  font-size: 1rem;
  padding: 0.875rem 1.5rem;
  border-radius: var(--border-radius);
  border: none;
  cursor: pointer;
  transition: var(--transition);
  box-shadow: 0 4px 20px rgba(114, 9, 183, 0.3);
}

.landing-page .primary-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 25px rgba(114, 9, 183, 0.4);
  color: white;
}

.landing-page .secondary-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background-color: transparent;
  color: var(--primary-color);
  font-weight: 600;
  font-size: 1rem;
  padding: 0.875rem 1.5rem;
  border-radius: var(--border-radius);
  border: 2px solid var(--primary-color);
  cursor: pointer;
  transition: var(--transition);
}

.landing-page .secondary-button:hover {
  background-color: rgba(114, 9, 183, 0.1);
  transform: translateY(-2px);
}

/* Hero Section */
.landing-page .hero-section {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6rem 0 4rem;
  overflow: hidden;
}

.landing-page .hero-container {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.landing-page .hero-content-wrapper {
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4rem;
}

.landing-page .hero-content {
  max-width: 500px;
  text-align: left;
}

.landing-page .hero-screenshot {
  flex: 1;
  max-width: 950px;
  position: relative;
  perspective: 1000px;
  cursor: pointer;
  z-index: 40;
}

.landing-page .screenshot-content {
  width: 100%;
  height: auto;
  transform: rotate(-5deg) translateY(0);
  transition: all 0.3s ease;
  transform-style: preserve-3d;
}

.landing-page .hero-screenshot img {
  width: 100%;
  height: auto;
  border-radius: 12px;
  /* box-shadow: 0 30px 60px rgba(0, 0, 0, 0.4); */
  display: block;
}

.landing-page .screenshot-glow {
  /* position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  background: radial-gradient(
    circle at 50% 50%,
    rgba(114, 9, 183, 0.3),
    transparent 70%
  );
  filter: blur(20px);
  opacity: 0.7;
  transform: rotate(-5deg) translateY(0) translateZ(-10px);
  z-index: -1;
  pointer-events: none; */
}

.landing-page .hero-subtitle {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  color: var(--text-light);
}

.landing-page .gradient-text {
  background: linear-gradient(135deg, var(--accent-color), var(--primary-color), var(--secondary-color));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  position: relative;
}

.landing-page .gradient-text::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(135deg, var(--accent-color), var(--primary-color), var(--secondary-color));
  border-radius: 3px;
}

.landing-page .cta-button-container {
  margin-top: 2rem;
}

.landing-page .no-credit-card {
  font-size: 0.875rem;
  color: var(--text-light);
  margin-top: 0.5rem;
}

.landing-page .hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.landing-page .gradient-blob {
  position: absolute;
  border-radius: 50%;
  filter: blur(80px);
  opacity: 0.6;
  animation: float 20s ease-in-out infinite;
}

.landing-page .blob-1 {
  width: 500px;
  height: 500px;
  background: radial-gradient(circle, var(--primary-light), var(--primary-dark));
  top: -100px;
  left: -100px;
  animation-delay: 0s;
}

.landing-page .blob-2 {
  width: 400px;
  height: 400px;
  background: radial-gradient(circle, var(--secondary-color), var(--primary-color));
  bottom: -100px;
  right: -100px;
  animation-delay: -5s;
}

.landing-page .blob-3 {
  width: 300px;
  height: 300px;
  background: radial-gradient(circle, var(--accent-color), var(--primary-light));
  top: 50%;
  right: 10%;
  animation-delay: -10s;
}

.landing-page .blob-4 {
  width: 450px;
  height: 450px;
  background: radial-gradient(circle, var(--primary-light), var(--accent-color));
  bottom: -150px;
  left: 10%;
  animation-delay: -7s;
}

.landing-page .blob-5 {
  width: 350px;
  height: 350px;
  background: radial-gradient(circle, var(--secondary-color), var(--primary-dark));
  top: -100px;
  right: 20%;
  animation-delay: -3s;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(-20px) scale(1.05);
  }
}

/* Floating models animation */
.landing-page .floating-models-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: none;
  overflow: hidden;
}

.landing-page .floating-model {
  position: absolute;
  padding: 8px 12px;
  border-radius: 20px;
  color: white;
  font-size: 12px;
  font-weight: 500;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  pointer-events: none;
}

/* Demo Section */
.landing-page .demo-section {
  padding: 6rem 0;
  background-color: var(--bg-dark);
}

.landing-page .video-container {
  margin-top: 3rem;
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: var(--card-shadow);
}

.landing-page .video-placeholder {
  background: linear-gradient(135deg, #2a2a2a, #1a1a1a);
  aspect-ratio: 16 / 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  transition: var(--transition);
}

.landing-page .video-placeholder:hover {
  transform: scale(1.02);
}

.landing-page .play-button {
  width: 80px;
  height: 80px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  transition: var(--transition);
}

.landing-page .play-button svg {
  width: 30px;
  height: 30px;
  margin-left: 5px;
}

.landing-page .video-placeholder:hover .play-button {
  background-color: var(--primary-color);
  transform: scale(1.1);
}

/* Why Choose Section */
.landing-page .why-choose-section {
  padding: 6rem 0;
}

.landing-page .features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
}

.landing-page .feature-card {
  background-color: var(--bg-dark);
  padding: 2rem;
  border-radius: var(--border-radius);
  transition: var(--transition);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: var(--card-shadow);
}

.landing-page .feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
}

.landing-page .feature-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background: linear-gradient(135deg, var(--primary-color), var(--primary-dark));
  border-radius: 12px;
  margin-bottom: 1.5rem;
  color: white;
}

.landing-page .feature-card h3 {
  margin-bottom: 0.75rem;
}

/* Pricing Section */
.landing-page .pricing-section {
  padding: 6rem 0;
  background-color: var(--bg-dark);
}

.landing-page .pricing-comparison {
  display: flex;
  align-items: stretch;
  gap: 2rem;
}

.landing-page .pricing-table {
  flex: 1;
  min-width: 300px;
  background-color: var(--bg-color);
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: var(--card-shadow);
}

.landing-page .pricing-body {
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;
}

.landing-page .pricing-row {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.landing-page .pricing-total {
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 0;
  margin-top: 1rem;
  border-top: 2px solid var(--primary-color);
  font-weight: 700;
  font-size: 1.1rem;
}

.landing-page .pricing-vs {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--text-light);
}

.landing-page .pricing-card {
  flex: 1;
  min-width: 300px;
  background-color: var(--bg-color);
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: var(--card-shadow);
  border: 2px solid var(--primary-color);
  transform: scale(1.05);
}

.landing-page .pricing-header {
    background-color: var(--bg-dark);
    padding: 2rem 1.5rem;
    text-align: center;
  }

.landing-page .pricing-card-header {
  background: linear-gradient(135deg, var(--primary-color), var(--primary-dark));
  padding: 2rem 1.5rem;
  text-align: center;
  color: white;
}

.landing-page .pricing-amount {
  margin-top: 1rem;
}

.landing-page .price {
  font-size: 3rem;
  font-weight: 700;
}

.landing-page .period {
  font-size: 1.1rem;
  opacity: 0.8;
}

.landing-page .pricing-card-body {
  padding: 2rem 1.5rem;
}

.landing-page .pricing-features {
  list-style: none;
  padding: 0;
  margin: 0 0 2rem;
}

.landing-page .pricing-features li {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1rem;
}

.landing-page .pricing-features li svg {
  color: var(--success-color);
}

.landing-page .pricing-note {
  text-align: center;
  font-size: 0.875rem;
  color: var(--text-light);
  margin-top: 1rem;
}

.landing-page .savings-callout {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 3rem;
  padding: 1.5rem;
  background: linear-gradient(135deg, var(--accent-color), var(--primary-color));
  border-radius: var(--border-radius);
  color: white;
}

.landing-page .savings-callout h3 {
  margin: 0;
}

/* Models Section */
.landing-page .models-section {
  padding: 6rem 0;
}

.landing-page .models-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1.5rem;
  margin-top: 3rem;
}

.landing-page .model-item {
  background-color: var(--bg-dark);
  padding: 1.25rem;
  border-radius: var(--border-radius);
  text-align: center;
  transition: var(--transition);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.landing-page .model-item:hover {
  transform: translateY(-5px);
  box-shadow: var(--card-shadow);
  background: linear-gradient(135deg, rgba(114, 9, 183, 0.1), rgba(76, 201, 240, 0.1));
}

.landing-page .models-note {
  text-align: center;
  margin-top: 2rem;
  font-style: italic;
  color: var(--text-light);
}

/* Testimonials Section */
.landing-page .testimonials-section {
  padding: 6rem 0;
  background-color: var(--bg-dark);
}

.landing-page .testimonials-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
}

.landing-page .testimonial-card {
  background-color: var(--bg-color);
  border-radius: var(--border-radius);
  padding: 2rem;
  box-shadow: var(--card-shadow);
  transition: var(--transition);
}

.landing-page .testimonial-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
}

.landing-page .testimonial-content {
  margin-bottom: 1.5rem;
  font-style: italic;
}

.landing-page .testimonial-author {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.landing-page .avatar-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: linear-gradient(135deg, #6366f1, #8b5cf6);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
}

.landing-page .testimonial-author h4 {
  margin: 0;
  font-size: 1rem;
}

.landing-page .testimonial-author p {
  margin: 0;
  font-size: 0.875rem;
  color: var(--text-light);
}

/* FAQ Section */
.landing-page .faq-section {
  padding: 6rem 0;
}

.landing-page .faq-container {
  max-width: 800px;
  margin: 3rem auto 0;
}

.landing-page .faq-item {
  margin-bottom: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius);
  overflow: hidden;
  transition: var(--transition);
}

.landing-page .faq-item.open {
  box-shadow: var(--card-shadow);
}

.landing-page .faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  cursor: pointer;
  background-color: var(--bg-color);
}

.landing-page .faq-question h3 {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 600;
}

.landing-page .faq-icon {
  color: var(--primary-color);
}

.landing-page .faq-answer {
  padding: 0 1.5rem 1.5rem;
  overflow: hidden;
}

/* Final CTA Section */
.landing-page .final-cta-section {
  position: relative;
  padding: 8rem 0;
  overflow: hidden;
}

.landing-page .cta-content {
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 2;
}

.landing-page .cta-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}

.landing-page .cta-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

/* Footer */
.landing-page .footer {
  background-color: var(--bg-dark);
  padding: 4rem 0 2rem;
}

.landing-page .footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.landing-page .footer-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
  flex-wrap: wrap;
  gap: 2rem;
}

.landing-page .footer-logo h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.landing-page .footer-logo p {
  color: var(--text-light);
}

.landing-page .footer-links {
  display: flex;
  gap: 4rem;
  flex-wrap: wrap;
}

.landing-page .footer-links-column h4 {
  font-size: 1rem;
  margin-bottom: 1.25rem;
}

.landing-page .footer-links-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.landing-page .footer-links-column ul li {
  margin-bottom: 0.75rem;
}

.landing-page .footer-links-column ul li a {
  color: var(--text-light);
  transition: var(--transition);
}

.landing-page .footer-links-column ul li a:hover {
  color: var(--primary-color);
}

.landing-page .footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  flex-wrap: wrap;
  gap: 1rem;
}

.landing-page .footer-bottom p {
  margin: 0;
  font-size: 0.875rem;
  color: var(--text-light);
}

.landing-page .social-links {
  display: flex;
  gap: 1rem;
}

.landing-page .social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--text-light);
  transition: var(--transition);
}

.landing-page .social-links a:hover {
  background-color: var(--primary-color);
  color: white;
  transform: translateY(-3px);
}

.landing-page .social-links a svg {
  width: 18px;
  height: 18px;
}

/* Responsive styles */
@media (max-width: 1200px) {
  .landing-page .hero-screenshot {
    max-width: 650px;
  }
}

@media (max-width: 992px) {
  .landing-page .hero-content-wrapper {
    flex-direction: column;
    text-align: center;
    gap: 3rem;
  }
  
  .landing-page .hero-content {
    max-width: 100%;
    text-align: center;
  }
  
  .landing-page .hero-screenshot {
    margin-top: 1rem;
    max-width: 80%;
  }
  
  .landing-page .hero-screenshot img,
  .landing-page .screenshot-glow {
    transform: rotate(-3deg) translateY(0);
  }
}

@media (max-width: 768px) {
  .landing-page .hero-section {
    padding-top: 5rem;
  }
  
  .landing-page h1 {
    font-size: 2.5rem;
  }
  
  .landing-page .hero-screenshot {
    max-width: 100%;
  }
  
  .landing-page .hero-screenshot img,
  .landing-page .screenshot-glow {
    transform: rotate(-2deg) translateY(0);
  }
}

/* Animation utilities */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

.landing-page .animate-fadeIn {
  animation: fadeIn 0.8s ease forwards;
}

.landing-page .animate-slideUp {
  animation: slideUp 0.8s ease forwards;
}

.landing-page .animate-pulse {
  animation: pulse 2s infinite;
}

/* Glossy effects */
.landing-page .glossy {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.landing-page .glossy-dark {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
}

/* Navbar */
.landing-page .navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: var(--transition);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.landing-page .navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem 2rem;
}

.landing-page .navbar-logo a {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--text-dark);
  text-decoration: none;
}

.landing-page .logo-arrow {
  color: var(--text-dark);
}

.landing-page .logo-ai {
  background: linear-gradient(135deg, var(--accent-color), var(--primary-color), var(--secondary-color));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.landing-page .navbar-menu {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.landing-page .navbar-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 2rem;
}

.landing-page .navbar-links li a {
  color: var(--text-color);
  font-weight: 500;
  transition: var(--transition);
  position: relative;
}

.landing-page .navbar-links li a:hover {
  color: var(--primary-color);
}

.landing-page .navbar-links li a::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 0;
  height: 2px;
  background: linear-gradient(135deg, var(--accent-color), var(--primary-color));
  transition: var(--transition);
}

.landing-page .navbar-links li a:hover::after {
  width: 100%;
}

.landing-page .navbar-buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.landing-page .login-button {
  color: var(--text-color);
  font-weight: 600;
  transition: var(--transition);
}

.landing-page .login-button:hover {
  color: var(--primary-color);
}

.landing-page .signup-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, var(--primary-color), var(--primary-dark));
  color: white;
  font-weight: 600;
  padding: 0.5rem 1.25rem;
  border-radius: var(--border-radius);
  transition: var(--transition);
  box-shadow: 0 4px 15px rgba(114, 9, 183, 0.2);
}

.landing-page .signup-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(114, 9, 183, 0.3);
  color: white;
}

.landing-page .mobile-menu-toggle {
  display: none;
  background: none;
  border: none;
  color: var(--text-color);
  cursor: pointer;
  padding: 0.5rem;
}

/* Adjust hero section to account for navbar */
.landing-page .hero-section {
  padding-top: 6rem;
}

/* Mobile responsive navbar */
@media (max-width: 768px) {
  .landing-page .navbar-menu {
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;
    height: 0;
    flex-direction: column;
    background: var(--bg-color);
    overflow: hidden;
    transition: height 0.3s ease;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    gap: 0;
  }
  
  .landing-page .navbar-menu.active {
    height: 100vh;
    padding: 2rem 0;
  }
  
  .landing-page .navbar-links {
    flex-direction: column;
    width: 100%;
    text-align: center;
    gap: 1rem;
    margin-bottom: 2rem;
  }
  
  .landing-page .navbar-links li {
    padding: 0.75rem 0;
  }
  
  .landing-page .navbar-buttons {
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    padding: 0 2rem;
  }
  
  .landing-page .login-button, .landing-page .signup-button {
    width: 100%;
    text-align: center;
    padding: 0.75rem 0;
  }
  
  .landing-page .mobile-menu-toggle {
    display: block;
  }
}

/* Navbar scroll effect */
.landing-page .navbar.scrolled {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}

@media (prefers-color-scheme: dark) {
  .landing-page .navbar.scrolled {
    background: rgba(18, 18, 18, 0.8);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  }
}

/* Model Pills styling */
.landing-page .model-pills-container {
  width: 100%;
  overflow: hidden;
  margin: 2rem 0;
  position: relative;
}

.landing-page .model-pills {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
  padding: 1rem;
}

.landing-page .model-pill {
  padding: 8px 16px;
  border-radius: 50px;
  color: white;
  font-weight: 500;
  font-size: 0.9rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.landing-page .model-pill:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

/* Optional animation to stagger the pills appearance */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.landing-page .model-pill {
  animation: fadeIn 0.5s ease forwards;
  opacity: 0;
}

/* Apply staggered delay to each pill */
.landing-page .model-pill:nth-child(1) { animation-delay: 0.05s; }
.landing-page .model-pill:nth-child(2) { animation-delay: 0.1s; }
.landing-page .model-pill:nth-child(3) { animation-delay: 0.15s; }
.landing-page .model-pill:nth-child(4) { animation-delay: 0.2s; }
.landing-page .model-pill:nth-child(5) { animation-delay: 0.25s; }
.landing-page .model-pill:nth-child(6) { animation-delay: 0.3s; }
.landing-page .model-pill:nth-child(7) { animation-delay: 0.35s; }
.landing-page .model-pill:nth-child(8) { animation-delay: 0.4s; }
.landing-page .model-pill:nth-child(9) { animation-delay: 0.45s; }
.landing-page .model-pill:nth-child(10) { animation-delay: 0.5s; }
.landing-page .model-pill:nth-child(11) { animation-delay: 0.55s; }
.landing-page .model-pill:nth-child(12) { animation-delay: 0.6s; }
.landing-page .model-pill:nth-child(13) { animation-delay: 0.65s; }
.landing-page .model-pill:nth-child(14) { animation-delay: 0.7s; }
.landing-page .model-pill:nth-child(15) { animation-delay: 0.75s; }
.landing-page .model-pill:nth-child(16) { animation-delay: 0.8s; }
.landing-page .model-pill:nth-child(17) { animation-delay: 0.85s; }
.landing-page .model-pill:nth-child(18) { animation-delay: 0.9s; }
.landing-page .model-pill:nth-child(19) { animation-delay: 0.95s; }
.landing-page .model-pill:nth-child(20) { animation-delay: 1s; }
  
/* Equal height for pricing cards */
.landing-page .equal-height-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 380px;
}

/* Service info with notes */
.landing-page .pricing-service-info {
  display: flex;
  flex-direction: column;
}

.landing-page .pricing-service-info small {
  font-size: 0.75rem;
  color: var(--text-secondary);
  margin-top: 0.25rem;
}

/* Price and toggle layout */
.landing-page .pricing-price-toggle {
  display: flex;
  align-items: center;
  gap: 1rem;
}

/* Toggle switch styling */
.landing-page .switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
}

.landing-page .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.landing-page .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.landing-page .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
}

.landing-page input:checked + .slider {
  background-color: var(--primary-color);
}

.landing-page input:focus + .slider {
  box-shadow: 0 0 1px var(--primary-color);
}

.landing-page input:checked + .slider:before {
  transform: translateX(18px);
}

.landing-page .slider.round {
  border-radius: 34px;
}

.landing-page .slider.round:before {
  border-radius: 50%;
}

.app-store-buttons {
  display: flex;
  gap: 12px;
  margin-top: 16px;
}

.app-store-button {
  height: 40px;
  transition: transform 0.2s ease;
}

.app-store-button:hover {
  transform: translateY(-2px);
}

.app-store-button img {
  height: 100%;
  width: auto;
}